import * as React from "react"
import { Link } from "gatsby"
import Hero from '../components/Hero';

// markup
const NotFoundPage = () => {
  return (
    <>
    <Hero />
    <div className='mo-colorwrap mo-colorwrap--white mo-colorwrap--white--border'>
      <div className='mo-grid'>
          <div className='mo-stand-alone'>
          <title>Not found</title>
          <h2>Page not found</h2>
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br /><br />
            <Link to="/" className="mo-button mo-button--purple">Go home</Link>
          </p>
        </div>
      </div>
    </div>
    </>
  )
}

export default NotFoundPage
